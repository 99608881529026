import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { deleteUser } from '../../api/user'
import { styles } from './styles'
import { HOME_PATH } from '../../../../configs'

const DeleteUserButton = props => {
  const { classes, user, activeBot, title, history } = props

  const handleClick = () => {

    deleteUser(activeBot.id, user.chatId, activeBot?.publicIdentifier).then(() => {
      history.push(`${HOME_PATH}/bot/${activeBot.id}/support`)
      window.location.reload()
    })
  }

  return (
    <div className={classes.container} onClick={handleClick}>
      {title}
    </div>
  )
}

DeleteUserButton.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  activeBot: PropTypes.object,
  title: PropTypes.string,
  history: PropTypes.object,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
  user: state.activeUser,
})

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(DeleteUserButton)),
)
